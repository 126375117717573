import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/layouts/blogPost.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "airdrop-for-early-users-of-pegasys",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#airdrop-for-early-users-of-pegasys",
        "aria-label": "airdrop for early users of pegasys permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Airdrop for early users of Pegasys`}</h1>
    <h2 {...{
      "id": "here-is-what-you-want-to-know",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#here-is-what-you-want-to-know",
        "aria-label": "here is what you want to know permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{` Here is what you want to know `}</strong></h2>
    <p>{`One of our main purposes regarding airdrop distribution is to make sure to have a fair token distribution
to avoid airdrop hunters. So, according to what we have said previously, users who added liquidity to the protocol (leveraging Multichain’s officially bridged tokens and $SYS) and/or performed swaps equal or above 200SYS in the DEX from February 16th to March 10th, 2022, will be eligible for a proportional PSYS airdrop.`}</p>
    <p>{`We also want to emphasize that other airdrops will be carried out in the first months after the launch of farming, staking, and the token PSYS (happening on April 27th, 2022) with surprise dates for users who interacted with the protocol and actively participated in our community.`}</p>
    <h2 {...{
      "id": "here-are-the-airdrop-definitions-for-pegasyss-early-users",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#here-are-the-airdrop-definitions-for-pegasyss-early-users",
        "aria-label": "here are the airdrop definitions for pegasyss early users permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{` Here are the airdrop definitions for Pegasys’s early users `}</strong></h2>
    <p>{`Total amount distributed in the first airdrop: `}<strong parentName="p">{` 1,000,000 PSYS `}</strong></p>
    <p>{`Pools that grant eligibility:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`SYS_USDC`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`SYS_ETH`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`SYS_USDT`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`SYS_WBTC`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`SYS_DAI`}</p>
      </li>
    </ul>
    <p>{`Snapshot Block: `}<strong parentName="p">{` 53393 `}</strong></p>
    <h2 {...{
      "id": "swap---30-300000-tokens",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#swap---30-300000-tokens",
        "aria-label": "swap   30 300000 tokens permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{` Swap - 30% (300,000 tokens) `}</strong></h2>
    <p>{`All the users with an amount of more than `}<strong parentName="p">{` 200 SYS `}</strong>{` in swaps up to the snapshot block in these pools are eligible for the airdrop.`}</p>
    <h2 {...{
      "id": "total-of-458-eligible-addresses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#total-of-458-eligible-addresses",
        "aria-label": "total of 458 eligible addresses permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{` Total of 458 eligible addresses `}</strong></h2>
    <p>{`The part dedicated to the swap is divided equally among all eligible users. Which means:`}</p>
    <p>{`300000/458 = `}<strong parentName="p">{` 655,021 `}</strong>{` tokens per user.`}</p>
    <p>{`Considering the launch price of US$ 0.14 it is US$ 91 worth of $PSYS.`}</p>
    <h2 {...{
      "id": "pools---70-700000-tokens",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#pools---70-700000-tokens",
        "aria-label": "pools   70 700000 tokens permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{` Pools - 70% (700,000 tokens) `}</strong></h2>
    <p>{`A snapshot was taken of all the LPTokens balances referring to these pools in the snapshot block, and weight was defined for each of the pools based on the amount of liquidity it has.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`SYS_USDC`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`46.5%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`SYS_ETH`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`33%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`SYS_USDT`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`5.5%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`SYS_WBTC`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`14.5%`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`SYS_DAI`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`0.5%`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "total-of-1056-eligible-addresses",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#total-of-1056-eligible-addresses",
        "aria-label": "total of 1056 eligible addresses permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><strong parentName="h2">{` Total of 1056 eligible addresses. `}</strong></h2>
    <p>{`The final value for each user is defined by: (∑LP Amount LP Weight) 700,000.`}</p>
    <p>{`The sum of liquidity in these pools at the time of the snapshot was US$ 1,399,509 which means that we will distribute 0.5 token per dollar locked in liquidity, or 7 cents per dollar, with the launch price at US$ 0.14.`}</p>
    <p>{`Therefore, the 1,000,000 PSYS will be distributed between 1230 users (since there are accounts that provided liquidity and also swapped).`}</p>
    <p>{`The airdrop for early users of Pegasys will be available at token launch on April 27th, 2022 on `}<a parentName="p" {...{
        "href": "https://app.pegasys.fi"
      }}>{`app.pegasys.fi`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      